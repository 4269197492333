<template>
    <div class="my-lesson">
        <div class="head">
            <h3 class="title">{{$t("lesson.list")}}</h3>
            <router-link 
                to="/my-page/lesson-create" 
                class="btn btn-add"
                >{{$t("lesson.sell")}}</router-link>
        </div>
        <div class="body">
            <template class="text-center" v-if="list.data.length === 0" >
                <Empty/>
            </template>
            <template v-else>
                <Item 
                    v-for="(item, key) in list.data" 
                    :item="item"  
                    :key="key"
                />
            </template>
        </div>
        <div class="foot">
            <Pagination
                :length="list.pagination.last"
                v-model="filters.page"
                @input="onPageChange"
                v-if="list.pagination.last > 1"
            />
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import Empty from './empty.vue'
import Item from './item.vue';
export default {
    name: "List",
    data() {
        return {
            filters: {
                limit: 10,
                page: 1
            }
        };
    },
    async created() {
        this.filters = this.$router.getQuery(this.filters);
        await this.getLessons(this.filters);
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.filters = this.$router.getQuery(this.filters);
                this.$router.setQuery(this.filters);
                this.getLessons(this.filters);
            },
        },
    },
    computed: {
        ...mapGetters({
            list: "lesson/listLesson"
        })
    },
    methods: {
        ...mapActions({
            getLessons: "lesson/getLessons"
        }),
        onPageChange(page) {
            this.filters = {
                ...this.filters,
                page
            }
            this.$router.setQuery(this.filters);
        },
    },
    components: {
    Empty,
    Item
}
}
</script>
<style scoped>
.my-lesson .body {
    min-height: auto !important;
    width: 100% !important;

}
.my-lesson .head {
    width: 100% !important;
}
</style>